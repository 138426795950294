// export const HOST_PREFIX = 'https://';
// export const HOST_SUFFIX = '063o08hn77.execute-api.us-east-1.amazonaws.com/econo/';
export const REFRESH_SECONDS = 600;

export const APP_ID = 'e1640c44cc09533532771051272857d6c158b6886b018ee1a5649ede88b30dc3'; // shelfos
export const HOST_PREFIX = process.env.REACT_APP_HOST_PREFIX || 'http://';
export const HOST_SUFFIX = process.env.REACT_APP_HOST_SUFFIX || 'localhost:3000/';
export const DEV_MODE = !!process.env.REACT_APP_DEV_MODE;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '';


if (DEV_MODE) {
    console.log("DEV_MODE");
}

// Planogram Configuration Constants
export const PLANOGRAM_TABS = {
    SECTION: 'section',
    REFERENCE_POINTS: 'reference_points',
    SHELVES: 'shelves',
    TRACKERS: 'trackers',
    FACING_HEIGHT: 'heights',
    PRODUCTS: 'products',
    THREE_D: 'three_dimensions'
}


export const DEFAULT_OVERFLOW = 10;
export const MIN_TRACKER_POINTCLOUD_INTERSECT_PCT = .90;


export const EDIT_STATES = {
    NONE: 'none',
    ADDING: 'adding',
    DELETING: 'deleting'
}

export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const MOMENT_DATE_TIME_FORMAT_TZ = 'YYYY-MM-DD HH:mm z';
export const MOMENT_TIME_FORMAT = 'HH:mm a'
export const UTC_TZ = 'GMT';

export const PRODUCT_EVENT_STATUS = {
    HIGH: 'HIGH',
    LOW: 'LOW',
    OUT: 'OUT'
}

export const DEFAULT_PRODUCT_EVENT_ERRORS = {
    people_in_frame: false,
    carts_in_frame: false,
    crates_in_frame: false,
    merchandiser_in_frame: false,
    product_out_of_region_of_interest: false,
    product_disordered: false,
    product_not_visible: false,
    displacement: false
}

export const DEFAULT_NO_ACTION_FLAGS_TO_SEND = {
    no_hay_producto_en_almacen: false,
    suplidor_no_tiene_productos: false,
    esperando_suplidor: false,
    planorama_incorrecto: false,
    no_actions: false
}

export const VOXEL_STATES = {
    EMPTY: 0,
    UNCERTAIN: 1,
    FILLED: 2,
    SHADOW: 3,
};

export const VOXEL_STATES_COLOR_MAP = {
    [VOXEL_STATES.EMPTY]: '#750000',
    [VOXEL_STATES.UNCERTAIN]: '#505661',
    [VOXEL_STATES.FILLED]: '#1cb050',
    [VOXEL_STATES.SHADOW]: '#1c4bb0',
};


export const CONTENT_MODE = {
    NORMAL: 'normal',
    EDITOR: 'editor',
}