import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import translationEN from './assets/locales/en/translation.json';
import translationES from './assets/locales/es/translation.json';

// the translations
const resources = {
    en: { translation: translationEN },
    es: { translation: translationES },
};

i18n.use(reactI18nextModule).init({
    resources,
    lng: 'en',
    fallbackLng: ['en', 'es'],
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
