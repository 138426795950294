import fecthApi from '../utils/fetchApi';
import { APP_ID } from '../utils/constants';

const AuthApi = {
    async login({ account, username, password }) {
        return checkErrors(await fecthApi.akcelita1.post('api/login', {
            data: {
                account: account || "",
                username: username || "",
                password: password || ""
            },
            json: true
        }));
    },

    async setAccount({ id }) {
        return checkErrors(await fecthApi.akcelita1.api.post('login/account/', {data: { id }}));
    },

    async getAccounts() {
        return checkErrors(await fecthApi.akcelita1.api.get('user/accounts/'));
    },

    async forgotPassword({ email }) {
        return checkErrors(await fecthApi.commons.post('api/user/forgot-password', {
            data: {
                appid: APP_ID,
                email: email || ""
            },
            json: true
        }));
    },

    async resetPassword({ token, account, new_password, confirm_password }) {
        return checkErrors(await fecthApi.commons.post('api/user/reset-password', {
            data: { token, account, new_password, confirm_password },
            json: true
        }));
    },
}


function checkErrors({ status, errors, data }) {
    if (status === "ERR"){
        var error = errors;

        if (errors.msg.includes("Unknown database")){
            throw new Error("Account not found");
        }

        throw new Error(error.msg);
    } else {
        return data;
    }
}

export default AuthApi;
