import React, { useState, useMemo, useEffect } from 'react';

const NotificationContext = React.createContext()


function NotificationProvider({children}){
    const [_notifications, _setNotifications] = useState();

    const service = useMemo(() => ({
        notifications: _notifications,
        set(notifications){
            const createdAt = new Date().getTime();
            this.notifications = notifications.map(notification => {
                if (typeof notification == 'string') {
                    return { text: notification, createdAt };
                } else if (notification.error) {
                    return { text: notification.error, color: 'danger', createdAt };
                } else if (notification.info) {
                    return { text: notification.info, color: 'warning', createdAt };
                } else {
                    return { createdAt, ...notification };
                }
            });

            console.log("nots :: ", this.notifications);

            _setNotifications(this.notifications);
        },
        add(...notifications){
            const newList = [...(_notifications || []), ...(notifications || [])];
            this.set(newList.length ? newList : undefined);
        },
    }), [_notifications]);

    return (
        <NotificationContext.Provider value={service}>{children}</NotificationContext.Provider>
    )
}

const NotificationConsumer = NotificationContext.Consumer

export {
    NotificationContext,
    NotificationProvider,
    NotificationConsumer,
}
