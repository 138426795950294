import React, { useState, useMemo } from 'react'
import { Redirect, withRouter } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import useResourceLoader from '../utils/useResourceLoader.js'
import AuthApi from '../api/AuthApi';
import { DEV_MODE } from '../utils/constants.js'

import cookies from '../utils/cookies';

export const AuthContext = React.createContext()

function _AuthProvider(props) {
    const { children } = props;
    const hooks = useAuthProviderHooks(props);

    return (
        <AuthContext.Provider value={hooks}>
        {children}
        </AuthContext.Provider>
    )
}


export function getToken() {
    return cookies.get('token')
}

function useAuthProviderHooks({
    location
}) {
    const [token, setToken] = useState(getToken() || null);
    const [loading, setLoading] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [resetToken, setResetToken] = useState(null);

    const [loginLocation, setLoginLocation] = useState(location ? location.pathname + location.search + location.hash : '');

    const data = useMemo(() => {
        try {
            return jwt.decode(token);
        } catch {
            return {};
        }
    }, [token]);

    const [accounts, loadingAccounts, errorLoadingAccounts] = useResourceLoader(
        () => token ? AuthApi.getAccounts() : [],
        [token], []
    );

    async function login({account, username, password}) {
        setLoading(true);
        setError();

        try {
            const { token, permissions } = await AuthApi.login({account, username, password});

            cookies.set('token', token, { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})});
            cookies.set('permissions', permissions, { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})});
            setToken(token);

        } catch(error){
            setError({ msg: error.message});
        }

        setLoading(false);
    }

    async function selectAccount({ id }){
        setLoading(true);
        setError();

        try {
            const { token, permissions } = await AuthApi.setAccount({ id });

            cookies.set('token', token, { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})});
            cookies.set('permissions', permissions, { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})});
            setToken(token);

        } catch(error){
            setError({ msg: error.message});
        }

        setLoading(false);
    } 

    function logout() {
        cookies.remove('token', { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})})
        cookies.remove('permissions', { path: '/', ...(DEV_MODE ? {} : {domain: '.artificialsensing.com'})})

        setToken(null);
        setResetSuccess(false);
        setResetToken(null);
        setError(null);
        setLoading(false);
        setLoginLocation('/');
    }

    async function forgotPassword({email, propagateError}) {
        setLoading(true);
        setResetToken(null);

        try {
            const {email_job} = await AuthApi.forgotPassword({ email });

            setError(null);
            setResetToken(email_job);
            setLoading(false);
            setResetSuccess(false);

        } catch(error) {
            setError({ msg:  error.message });
            setLoading(false);
            setResetToken(null);
            setResetSuccess(false);

            if (propagateError) {
                throw error;
            }
        }
    }

    async function resetPassword({ token, account, newPassword, confirmPassword, propagateError }) {
        setLoading(true);

        try {
            await AuthApi.resetPassword({
                token, account,
                new_password: newPassword,
                confirm_password : confirmPassword
            });

            setError(null);
            setResetToken(null);
            setResetSuccess(true);
            setLoading(false);

            return (<Redirect to="/"/>)
        } catch(error) {
            setError({ msg: error.message });
            setLoading(false);
            setResetSuccess(false);

            if (propagateError) {
                throw error;
            }
        }
    }

    return {
        accounts,
        loginLocation: (!loginLocation || loginLocation === '/login') ? '/' : loginLocation,
        token,
        loading: loading || loadingAccounts,
        resetToken,
        error: error || errorLoadingAccounts,
        resetSuccess,
        data,
        selectAccount,
        resetPassword, login, logout, forgotPassword,
    }
}

export const AuthConsumer = AuthContext.Consumer

export const AuthProvider = withRouter(_AuthProvider);
