import React, { useState, useMemo, useEffect, useContext } from 'react';
import { CONTENT_MODE } from '../utils/constants';

const LayoutParamsContext = React.createContext()

function LayoutParamsContextProvider({children}){
    const [params, _setParams] = useState({
        contentMode: CONTENT_MODE.NORMAL,
    });

    const context = useMemo(() => ({
        set(newparams){_setParams({...params, ...newparams})},
        reset: _setParams,
        params,
    }), [params]);

    return (<LayoutParamsContext.Provider value={context}>
        {children}
    </LayoutParamsContext.Provider>);
}


function LayoutParams(props){
    const lpc = useContext(LayoutParamsContext);
    const depList = Object.entries(props).reduce((_, e) => {
        _.push(...e);
        return _;
    }, []);

    useEffect(() => {
        const oldparams = {...lpc.params};
        lpc.set(props);
        return () => {
            lpc.reset(oldparams)
        }
    }, [...depList]);

    return null;
}


export {
    LayoutParamsContext,
    LayoutParamsContextProvider,
    LayoutParams,
}
